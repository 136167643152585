import { network } from './network';

export async function getTokenUsage(params) {
    const res = await network({
        url: '/api/query_token_usage',
        method: 'get',
        params: params
    });

    const res_data = {
        code: res.code,
        data: res.data,
        message: res.message
    }
    return res_data;
}
import { sleep } from '@/utils/util';
import { getUserInfo } from '@/api/userInfo';

const actions = {
	async updateUserInfo({ commit, state, rootState }, payload) {
		try {
			const res = await getUserInfo();

			if (res.code === 0) {
				commit('UPDATE_USER_INFO', {
					isLogin: true,
					userName: res.data.userName,
					userType: res.data.userType,
					lemonBeanCount: res.data.lemon_bean_count,
					openId: res.data.open_id || '',
					setting: res.data.setting
				});
				return;
			}

			commit('UPDATE_USER_INFO', {
				isLogin: false,
				userName: '',
				userType: '',
				openId: '',
				setting: {}
			});
		}
		catch(err) {
			commit('UPDATE_USER_INFO', {
				isLogin: false,
				userName: '',
				userType: '',
				openId: '',
				setting: {}
			});
		}
	},

	async updateLemonBean({ commit, state, rootState }, payload) {
		try {
			const res = await getUserInfo();

			if (res.code === 0) {
				commit('UPDATE_LEMON_BEAN_COUNT', {
					lemonBeanCount: res.data.lemon_bean_count,
				});
			}
		}
		catch(err) {
			
		}
	},

	updateSetting({ commit, state, rootState }, payload) {
		commit('UPDATE_SETTING', {
			...payload
		});
	},

	loginOut({ commit, state, rootState }) {
		commit('UPDATE_USER_INFO', {
			isLogin: false,
			userName: '',
			userType: '',
			openId: '',
			setting: {}
		});
	}
}

export default actions;

import { network } from './network';

export async function addAPIKey(params) {
	const { api_key, type, business_username } = params;
	const res = await network({
		url: '/api/add_api_key',
		method: 'post',
		params: {
			api_key,
			type,
			business_username,
		}
	});

	return res;
}

export async function addCoupon(params) {
	const { discount, startTime, endTime, userUnionId } = params;
	const res = await network({
		url: '/api/add_coupon',
		method: 'post',
		params: {
			discount,
			start_time: startTime,
			end_time: endTime,
			user_union_id: userUnionId
		}
	});

	return res;
}

export async function queryCoupon(params) {
	const res = await network({
		url: '/api/query_coupon',
		method: 'get',
		params: {}
	});

	return res;
}

export async function actionCoupon(params) {
	const { couponId, actionType } = params;
	const res = await network({
		url: '/api/action_coupon',
		method: 'post',
		params: {
			coupon_id: couponId,
			action_type: actionType
		}
	});

	return res;
}
import { network } from './network';

export async function getUserActive(params) {
    const res = await network({
        url: '/api/query_user_active',
        method: 'get',
        params: params
    });
    
    const res_data = {
        code: res.code,
        data: res.data,
        message: res.message
    }
    return res_data;
}
import { getTokenUsage } from "@/api/tokenUsage";
import * as echarts from 'echarts';

export default {
    data() {
        return {
            tokenUsageForm: {
                dataRange: [],
                botType: '',
                userId: ''
            },
            tokenUsageData: [],
            tokenUsageDataDate: [],
            getTokenUsageDataLoading: false,
            botTypes: [
                {
                    value: 'chat3.5',
                    label: 'CHAT 3.5'
                },
                {
                    value: 'chat4.0',
                    label: 'CHAT 4.0'
                }
            ]
        }
    },

    methods: {
        async getTokenUsageData(params) {
            try {
                let res_data = await getTokenUsage(params);
                this.tokenUsageData = res_data.data.token_usage_data;
                this.tokenUsageDataDate = res_data.data.token_usage_data_date;
            } catch (error) {
                console.log(error);
            }
        },
        makeCharts() {
            let tokenUsageChart = echarts.init(document.getElementById('tokenUsageChart'));
            let tokenUsageOption = {
                title: {
                    text:'Token消耗统计'
                },
                tooltip: {},
                legend: {
                    data: ['num']
                },
                xAxis: {
                    data: this.tokenUsageDataDate
                },
                yAxis: {},
                series: [
                    {
                        name: '消耗的美金',
                        type: 'bar',
                        data: this.tokenUsageData
                    }
                ]
            };
            tokenUsageChart.setOption(tokenUsageOption);
        },

        async tokenUsageSubmit() {
            let params = {
                time_start: Date.parse(this.tokenUsageForm.dateRange[0]),
                time_end: Date.parse(this.tokenUsageForm.dateRange[1]),
                user_id: this.tokenUsageForm.userId,
                bot_type: this.tokenUsageForm.botType

            };

            await this.getTokenUsageData(params);
            this.makeCharts();
        },

        async init() {
            const today = new Date();
            today.setHours(0,0,0,0);
            let last_week = new Date();
            last_week.setDate(last_week.getDate() - 7);
            last_week.setHours(0,0,0,0);

            let params = {
                time_end: +today,
                time_start: +last_week
            }
            await this.getTokenUsageData(params);
            this.makeCharts();
        },
    },

    mounted: function() {
        this.init();
    }
}

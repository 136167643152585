import { network } from './network';

export async function addAPIKey(params) {
	const { api_key, type, business_username } = params;
	const res = await network({
		url: '/api/add_api_key',
		method: 'post',
		params: {
			api_key,
			type,
			business_username,
		}
	});

	return res;
}

export async function queryKeys(params) {
	const res = await network({
		url: '/api/query_keys',
		method: 'get',
		params: {}
	});

	return res.data.list;
}

export async function refreshKeys(params) {
	const res = await network({
		url: '/api/refresh_keys',
		method: 'get',
		params: {}
	});

	return res;
}
import Coupon from '@/components/Coupon/Coupon.vue';
import Notify from '@/components/Notify/Notify.vue';
import AddKey from '@/components/AddKey/AddKey.vue';
import UserActive from '@/components/Statistics/UserActive/UserActive.vue';
import TokenUsage from '@/components/Statistics/TokenUsage/TokenUsage.vue';

export default {
  name: 'page-admin',

  data() {
    return {
      
    }
  },

  methods: {
    init() {
      
    },
  },

  mounted() {
    this.init();
  },

  components: {
    Coupon,
    Notify,
    AddKey,
    UserActive,
    TokenUsage
  }
}
import dayjs from 'dayjs';
import { createNotify, queryNotify, actionNotify } from '@/api/notify';

export default {
	data() {
		return {
			notifyForm: {
				notifyTitle: '',
				notifyBody: '',
				startTime: null,
				endTime: null,
			},
			notifyRule: {
				notifyTitle: [
					{
						required: true,
						message: '不能为空',
						trigger: 'change'
					}
				],

				notifyBody: [
					{
						required: true,
						message: '不能为空',
						trigger: 'change'
					}
				],

				startTime: [
					{
						required: true,
						message: '不能为空',
						trigger: 'change'
					}
				],

				endTime: [
					{
						required: true,
						message: '不能为空',
						trigger: 'change'
					}
				]
			},
			addNotifyLoading: false,
			notifyRenderList: []
		};
	},

	mounted() {
		this.init();
	},

	methods: {
		init() {
			this.renderNotifyList();
		},

		transTime(time) {
      return dayjs(time).locale('zh-cn').format('YYYY-MM-DD HH:mm');
    },

    transStatus(status) {
    	if (status === 1) {
    		return '进行中';
    	}

    	if (status === 0) {
    		return '已停止';
    	}

    	return '未知状态';
    },

		async renderNotifyList() {
			const res = await queryNotify();

			this.notifyRenderList = res.data.list;
		},

		async actionNotify(row, actionType) {
			row.stopLoading = true;

			let type = 0;

			if (actionType === 'start') {
				type = 1;
			}

			try {
				const res = await actionNotify({
					notifyId: row.notify_id,
					actionType: type
				});

				row.stopLoading = false;
				if (res.code !== 0) {
					this.$message({
	          showClose: true,
	          message: res.message,
	          type: 'error'
	        });
	        return;
				}

				this.renderNotifyList();
				this.$message({
          showClose: true,
          message: '操作成功',
          type: 'success'
        });
			}
			catch(err) {
				console.log(err);
				this.$message({
          showClose: true,
          message: '出错了',
          type: 'error'
        });
			}
		},

		addNotify() {
			this.$refs.notifyForm.validate((valid) => {
				if (!valid) {
					this.$message({
	          showClose: true,
	          message: '表单填写错误',
	          type: 'error'
	        });
	   			return;
				}

				this.sendRequest();
      });
		},

		async sendRequest() {
			const notifyTitle = this.notifyForm.notifyTitle;
			const notifyBody = this.notifyForm.notifyBody;
			const startTime = this.notifyForm.startTime.getTime();
			const endTime = this.notifyForm.endTime.getTime();

			this.addNotifyLoading = true;
			try {
				const res = await createNotify({
					notifyTitle,
					notifyBody,
					startTime,
					endTime
				});

				this.addNotifyLoading = false;

				if (res.code !== 0) {
					this.$message({
	          showClose: true,
	          message: res.message,
	          type: 'error'
	        });
	   			return;
				}

				this.$message({
          showClose: true,
          message: '添加成功',
          type: 'success'
        });
        this.renderNotifyList();
			}
			catch(err) {
				console.log(err);
				this.addNotifyLoading = false;
				this.$message({
          showClose: true,
          message: '网络错误',
          type: 'error'
        });
			}
		}
	}
};
import { login } from '@/api/login';

export default {
  name: 'login',

  data() {
    return {
      userId: '',
      submiting: false
    };
  },

  methods: {
    async login() {
      if (!this.userId) {
        this.$message({
          message: '用户标识不能为空',
          type: 'error',
          customClass: 'custom-message',
        });
        return;
      }

      this.submiting = true;

      try {
        const res = await login({
          userId: this.userId
        });

        if (res.code !== 0) {
          this.$message({
            message: res.message,
            type: 'error',
            customClass: 'custom-message',
          });
          this.submiting = false;
          return;
        }

        await this.$store.dispatch('updateUserInfo');
        this.$router.push({
          name: 'admin',
        });
      }
      catch(err) {
        this.$message({
          message: '登录失败',
          type: 'error',
          customClass: 'custom-message',
        });
      }
      this.submiting = false;
    },
  },
}
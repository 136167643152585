const getters = {
	isLogin(state, getters) {
		return state.isLogin;
	},

	userType(state, getters) {
		return state.userType;
	},

	openId(state, getters) {
		return state.openId;
	},

	setting(state) {
		return state.setting;
	},

	lemonBeanCount(state) {
		return state.lemonBeanCount;
	}
};

export default getters
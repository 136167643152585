import { network } from './network';

export async function getUserInfo() {
	const res = await network({
		url: '/api/user_info',
		method: 'get',
		params: {}
	});

	return res;
}
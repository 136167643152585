import Vue from 'vue';
import App from './App.vue';
import ElementUI, { Loading } from 'element-ui';
import router from './router';
import store from './store';
import 'element-ui/lib/theme-chalk/index.css';

Vue.use(ElementUI);
Vue.config.productionTip = false;

async function getUserInfo() {
  const loading = Loading.service({
    fullscreen: true,
    customClass: 'chat-loading',
    background: '#232323'
  });

  await store.dispatch('updateUserInfo');
  loading.close();
}

Promise.all([
  getUserInfo()
]).then(() => {
  new Vue({
    router,
    store,
    render: h => h(App),
  }).$mount('#app');
});

import dayjs from 'dayjs';
import { addAPIKey, queryKeys, refreshKeys } from '@/api/keys';

export default {
	data() {
		return {
			apiKeyForm: {
        apiKey: '',
        type: 'chat3.5',
        business_username: ''
      },

      keys: [],

      rules: {
        apiKey: [
          { required: true, message: '请输入api_key', trigger: 'blur' },
        ],
        business_username: [
          { required: true, message: '请输入账号名称', trigger: 'blur' },
        ],
      },

      keyRefreshing: false
		};
	},

  mounted() {
    this.createKeyList();
  },

	methods: {
		addOpenKey() {
      this.$refs.openkeyForm.validate((valid) => {
        if (valid) {
          this.sendAddkeyRequest();
        }
      });
    },

    async createKeyList() {
      const list = await queryKeys();

      this.keys = list.map((keyInfo) => {
        console.log(keyInfo);
        return {
          ...keyInfo,
        };
      });
    },

    transTime(time) {
      return dayjs(time).locale('zh-cn').format('YYYY-MM-DD HH:mm');
    },

    async refreshKeyStatus() {
      this.keyRefreshing = true;
      
      const res = await refreshKeys();

      this.keyRefreshing = false;
      if (res.code === 0) {
        this.$message({
          message: '刷新成功',
          type: 'success'
        });
        this.createKeyList();
      }
    },

    async sendAddkeyRequest() {
      try {
        const res = await addAPIKey({
          api_key: this.apiKeyForm.apiKey,
          type: this.apiKeyForm.type,
          business_username: this.apiKeyForm.business_username
        });

        if (res.code !== 0) {
          this.$message({
            message: res.message,
            type: 'error'
          });
          return;
        }

        this.$message({
          message: '新增成功',
          type: 'success'
        });
        this.createKeyList();
        this.apiKeyForm.apiKey = '';
      } catch(err) {
        this.$message({
          message: err.message,
          type: 'error'
        });
      }
    },
	}
}
import { network } from './network';

export async function login(params) {
	const { userId } = params;
	const res = await network({
		url: '/api/login',
		method: 'post',
		params: {
			user_id: userId,
		}
	});

	return res;
}
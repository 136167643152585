<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>

<style lang="scss">
@import './markdown/style.scss';
@import './styles/dialog.scss';
@import './styles/message.scss';
@import './styles/popover.scss';
@import './styles/tooltip.scss';

.global-a {
  color: #FFD520;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

* {
  margin: 0;
  padding: 0;
}

body {
  font-family: "PingFang SC",miui,system-ui,-apple-system,BlinkMacSystemFont,Helvetica Neue,Helvetica,sans-serif;
  font-size: 12px;
}

.chat-loading .el-loading-spinner .path {
  stroke: #FFD520;
}

.chat-loading .el-loading-spinner .el-loading-text {
  color: #FFD520;
}

.custom-message.el-message {
  background-color: #2f2f2f;
  border: 1px solid #424242;
}

.custom-message.el-message .el-message__icon {
  color: #FFD520;
}

.custom-message.el-message .el-message__content{
  color: #d9dee8;
}
</style>

import { network } from './network';

export async function createNotify(params) {
	const { notifyTitle, startTime, endTime, notifyBody } = params;
	const res = await network({
		url: '/api/add_notify',
		method: 'post',
		params: {
			notify_title: notifyTitle,
			start_time: startTime,
			end_time: endTime,
			notify_body: notifyBody
		}
	});

	return res;
}

export async function queryNotify(params) {
	const res = await network({
		url: '/api/query_notify',
		method: 'get',
		params: {}
	});

	return res;
}

export async function actionNotify(params) {
	const { notifyId, actionType } = params;
	const res = await network({
		url: '/api/action_notify',
		method: 'post',
		params: {
			notify_id: notifyId,
			action_type: actionType
		}
	});

	return res;
}
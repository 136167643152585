import dayjs from 'dayjs';
import { addCoupon, queryCoupon, actionCoupon } from '@/api/admin';

export default {
	data() {
		return {
			couponForm: {
				discount: 1,
				startTime: null,
				endTime: null,
			},
			addCouponLoading: false,
			couponRule: {
				discount: [
					{
						required: true,
						message: '不能为空',
						trigger: 'change'
					}
				],

				startTime: [
					{
						required: true,
						message: '不能为空',
						trigger: 'change'
					}
				],

				endTime: [
					{
						required: true,
						message: '不能为空',
						trigger: 'change'
					}
				]
			},
			couponRenderList: []
		};
	},

	methods: {
		init() {
			this.renderCouponList();
		},

		transTime(time) {
      return dayjs(time).locale('zh-cn').format('YYYY-MM-DD HH:mm');
    },

    transStatus(status) {
    	if (status === 1) {
    		return '进行中';
    	}

    	if (status === 0) {
    		return '已停止';
    	}

    	return '未知状态';
    },

    transType(type) {
    	if (type === 0) {
    		return '柠檬豆购买折扣券';
    	}

    	return '未知类型';
    },

		async renderCouponList() {
			const res = await queryCoupon();
			const list = res.data.list;

			this.couponRenderList = list.map((item) => {
				return {
					...item,
					stopLoading: false
				};
			});
		},

		addCoupon() {
			const discount = Number(this.couponForm.discount);

			if (isNaN(discount) || (discount <= 0 || discount > 1)) {
				this.$message({
          showClose: true,
          message: '折扣不合法',
          type: 'error'
        });
        return;
			}

			this.$refs.couponForm.validate((valid) => {
				if (!valid) {
					this.$message({
	          showClose: true,
	          message: '表单填写错误',
	          type: 'error'
	        });
	   			return;
				}

				this.sendRequest();
      });
		},

		async actionCoupon(row, actionType) {
			row.stopLoading = true;

			let type = 0;

			if (actionType === 'start') {
				type = 1;
			}

			try {
				const res = await actionCoupon({
					couponId: row.coupon_id,
					actionType: type
				});

				row.stopLoading = false;
				if (res.code !== 0) {
					this.$message({
	          showClose: true,
	          message: res.message,
	          type: 'error'
	        });
	        return;
				}

				this.renderCouponList();
				this.$message({
          showClose: true,
          message: '操作成功',
          type: 'success'
        });
			}
			catch(err) {
				console.log(err);
				row.stopLoading = false;
				this.$message({
          showClose: true,
          message: '出错了',
          type: 'error'
        });
			}
		},

		async sendRequest() {
			const discount = Number(this.couponForm.discount);
			const startTime = this.couponForm.startTime.getTime();
			const endTime = this.couponForm.endTime.getTime();

			this.addCouponLoading = true;
			try {
				const res = await addCoupon({
					discount,
					startTime,
					endTime,
				});

				this.addCouponLoading = false;

				if (res.code !== 0) {
					this.$message({
	          showClose: true,
	          message: res.message,
	          type: 'error'
	        });
	   			return;
				}

				this.$message({
          showClose: true,
          message: '添加成功',
          type: 'success'
        });
        this.renderCouponList();
			}
			catch(err) {
				this.addCouponLoading = false;
				this.$message({
          showClose: true,
          message: '网络错误',
          type: 'error'
        });
			}
		}
	},

	mounted() {
		this.init();
	}
};
import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '@/pages/Login/Login.vue';
import Admin from '@/pages/Admin/Admin.vue';
import Forbidden from '@/pages/Forbidden/Forbidden.vue';
import NotFound from '@/pages/404/404.vue';
import store from '@/store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: '/admin',
  },
  {
    path: '/admin',
    name: 'admin',
    component: Admin,
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
];

const router = new VueRouter({
  base: '/',
  routes,
  mode: 'history'
});

// 登录态控制路由规则
router.beforeEach((to, from, next) => {
  const isLogin = store.getters.isLogin;
  const isManager = store.getters.userType === 'manager';

  // 如果直接访问的是登录页，并且没有登录，则渲染登录页面
  if (/\/login/.test(to.fullPath) && !isLogin) {
    next();
    return;
  }

  // 如果直接访问的是登录页，已登录，则重定向到聊天页面
  if (/\/login/.test(to.fullPath) && isLogin) {
    next('/');
    return;
  }

  // 除此之外没有登录，全部重定向到登录页去
  if (!isLogin) {
    next('/login');
    return;
  }

  // 具有登录态，正常渲染
  next();
})

export default router;

import { render, staticRenderFns } from "./Notify.vue?vue&type=template&id=359a28a8&scoped=true&"
import script from "./notify.js?vue&type=script&lang=js&"
export * from "./notify.js?vue&type=script&lang=js&"
import style0 from "./notify.scss?vue&type=style&index=0&id=359a28a8&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "359a28a8",
  null
  
)

export default component.exports
const mutations = {
	['UPDATE_USER_INFO'] (state, userInfo) {
		state.isLogin = userInfo.isLogin || false;
		state.userName = userInfo.userName || '';
		state.userType = userInfo.userType || '';
		state.openId = userInfo.openId || '';
		state.setting = userInfo.setting || {};
		state.lemonBeanCount = userInfo.lemonBeanCount || 0;
	},

	['UPDATE_SETTING'] (state, payload) {
		state.setting = payload;
	},

	['UPDATE_LEMON_BEAN_COUNT'] (state, payload) {
		const { lemonBeanCount } = payload;
		
		state.lemonBeanCount = lemonBeanCount;
	}
};

export default mutations;
import { getUserActive } from "@/api/userActive";
import * as echarts from 'echarts';

export default {
    data() {
        return {
            userActiveForm: {
                dateRange: []
            },
            userActiveDate: [],
            getUserActiveDataLoading: false,
            userActiveDataDate: [],
        }
    },

    methods: {
        async getUserActiveData(params) {
            
            try {
                let res_data = await getUserActive(params);
                this.userActiveDate = res_data.data.user_active_data;
                this.userActiveDataDate = res_data.data.data_date;
            } catch (error) {
                console.log(error);
            }
            
        },

        makeCharts() {
            let userActiveChart = echarts.init(document.getElementById('userActiveChart'));
            let option = {
                title: {
                    text:'活跃用户数'
                },
                tooltip: {},
                legend: {
                  data: ['num']
                },
                xAxis: {
                  data: this.userActiveDataDate
                },
                yAxis: {},
                series: [
                  {
                    name: '活跃用户数',
                    type: 'bar',
                    data: this.userActiveDate
                  }
                ]
            };
            userActiveChart.setOption(option);
        },

        async userActiveSubmit() {
            let params = {
                time_start: Date.parse(this.userActiveForm.dateRange[0]),
                time_end: Date.parse(this.userActiveForm.dateRange[1])
            };

            await this.getUserActiveData(params);
            this.makeCharts();
        },
        
        async init() {
            const today = new Date();
            today.setHours(0,0,0,0);
            let last_week = new Date();
            last_week.setDate(last_week.getDate() - 7);
            last_week.setHours(0,0,0,0);

            let params = {
                time_end: +today,
                time_start: +last_week
            }
            await this.getUserActiveData(params);
            this.makeCharts();
        },
    },

    mounted: function() {
        this.init();
    }
}
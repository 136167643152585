import axios from 'axios';

const showLog = localStorage.show_log === '1';

export async function network(opts) {
	const { method, params = {}, url } = opts;

	if (method === 'post') {
		return new Promise((resolve, reject) => {
			axios.post(url, {
			  ...params
			}).then((res) => {
				const body = res.data;

				resolve(body);
			}).catch((err) => {
				if (err.response && err.response.status === 401) {
					reject({
						code: 1000,
						message: '未登录'
					});
					return;
				}

				if (err.response && err.response.status === 403) {
					reject({
						code: 1001,
						message: '无权限'
					});
					return;
				}

				reject({
					code: 1002,
					message: '网络错误'
				});
			});
		});
	}

	if (method === 'get') {
		return new Promise((resolve, reject) => {
			axios.get(url, {
			  params
			}).then((res) => {
				const body = res.data;

				resolve(body);
			}).catch((err) => {
				if (err.response && err.response.status === 401) {
					reject({
						code: 1000,
						message: '未登录'
					});
					return;
				}

				if (err.response && err.response.status === 403) {
					reject({
						code: 1001,
						message: '无权限'
					});
					return;
				}

				reject({
					code: 1002,
					message: '网络错误'
				});
			});
		});
	}
}